import React from 'react';
import { Link } from 'react-router-dom';
import './loginpartialview.css';

export class LoginPartialView extends React.Component {
    formSubmitStyle = {
        textAlign: "center",
        margin: "auto",
        width: "240px",
        display: "grid",
        gridTemplateRows: "auto auto auto auto"
    }
    blockStyle = {
        backgroundColor: "white",
        borderRadius: "10px",
        display: "flex",
        border: "solid 1px #444",
        height: "auto",
        marginBottom: "8px",
        padding: "4px"
    }
    inputStyle = {
        backgroundColor: "transparent",
        fontSize: "0.8em",
        marginLeft: "4px",
        border: "none",
        outline: "none",
        alignItems: "center"
    }
    imgBlockStyle = {
        width: "24px",
        display: "flex",
        height: "auto"
    }
    
    constructor(props) {
        super(props);

        this.email = React.createRef();
        this.password = React.createRef();
    }
    validateEmail = (email) => {
        var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(email);
    }
    submit = (event) => {
        event.preventDefault();
        if (this.validateEmail(this.email.current.value)) {
            this.props.login(this.email.current.value, this.password.current.value);
        }
    }
    
    render() {
        return (
            <div className="loginPageMain">
                <div className="partLeftBack" />
                <div className="partLeftLogo">
                    <Link className="logo_link1" to="/" />
                </div>
                <div className="partLeftText" >
                    <b >WELCOME BACK !</b>
                    <span style={{ fontSize: "1.1em" }}>Enter your Email and Password to continue.<br />
                            Press <Link to="#" style={{ color: "#444", fontWeight: "bold" }}
                            onClick={() => this.props.showView('register')}>Register</Link> if you don't have an account
                        </span>
                </div>
                
                <div className="partCenterImage">
                    <img alt="" src="/images/imageim1.png" style={{ height: "500px", width: "100px" }}/>
                </div>

                <div className="partRightBack" />
        
                <div className="partRightLogin" >
                    <form style={this.formSubmitStyle} onSubmit={this.submit}>
                        <div style={{ marginBottom: "10px" }}>
                            <span style={{ display: "block", fontSize: "1.3em", marginBottom: "-8px" }}>SIGN IN</span>
                            <span style={{ fontSize: "0.9em" }}>TO ACCESS THE PORTAL</span>
                        </div>
                        <div style={this.blockStyle} >
                            <div style={this.imgBlockStyle}>
                                <img alt="email" srcSet="/images/emailIcon.png" style={{ height: "12px", margin: "auto" }} />
                            </div>
                            <input placeholder="Enter Email" type="email" ref={this.email} style={this.inputStyle} />
                        </div>
                        <div style={this.blockStyle} >
                            <div style={this.imgBlockStyle}>
                                <img alt="lock" srcSet="/images/lockIcon.png" style={{ height: "14px", margin: "auto" }} />
                            </div>
                            <input placeholder="Enter password" ref={this.password} type="password" style={this.inputStyle} />
                        </div>
                        <input type="submit" className="btn btn-primary" style={{ height: "auto" }} value="Login" />
                    </form>
                </div>
                <div className="partRightBottom" >
                    <div>Idea and development: AQL.exe (<a href="mailto:admin@grootplek.com">write me</a>)</div>
                </div>
           
            </div>
            )
    }
}