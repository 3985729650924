import React from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown } from 'reactstrap';

export class MExpenseAddCostModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen, selGroup: { name: '', id: null }, dropDownOpen: false, items: [] };
        this.name = React.createRef();
        this.value = React.createRef();
    }
    componentWillReceiveProps(props) {
        var selG = props.selectedGroup;
        if (selG.id === undefined)
            selG = props.items[0];
        this.setState({
            isOpen: props.isOpen,
            items: props.items,
            selGroup: selG ?? {}
        });
    }
    onOk = () => {
        if (this.state.selGroup.id === undefined
            || !this.name.current.value
            || !this.value.current.value)
            return;
        this.props.onOk(this.state.selGroup.id, this.name.current.value, parseFloat(this.value.current.value));
    }
    onCancel = () => {
        this.props.onCancel();
    }
    select = (el) => {
        this.setState({ selGroup: el });
    }

    render() {
        return (
            <Modal toggle={() => this.onCancel()}
                isOpen={this.state.isOpen}
                aria-labelledby="contained-modal-title-vcenter" size="sm" >
                <ModalHeader>Add Cost</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Group</Label>
                        <ButtonDropdown direction="left" style={{ width: "100%" }} isOpen={this.state.dropDownOpen} toggle={() => this.setState({ dropDownOpen: !this.state.dropDownOpen })}>
                            <Input id="caret" color="primary" readOnly value={this.state.selGroup.name} />
                            <DropdownToggle caret color="primary" />
                            <DropdownMenu>
                                {this.state.items.map(a => {
                                    return <DropdownItem key={a.id} onClick={() => this.select(a)}>{a.name}
                                    </DropdownItem>
                                })}                            
                            </DropdownMenu>
                        </ButtonDropdown >
                    </FormGroup>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input innerRef={this.name}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Plan</Label>
                        <Input innerRef={this.value} autoFocus />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => this.onOk()}>OK</Button>
                    <Button color="secondary" onClick={() => this.onCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}