import React from 'react';
import { ModuleApi } from '../../api/ModuleApi';
import { MExpenseGroupView } from './MExpenseGroupView';
import { MExpenseCostView } from './MExpenseCostView';
import { MExpenseCostEditView } from './MExpenseCostEditView';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, NavLink } from 'reactstrap';
import { MExpenseAddCostModal } from './MExpenseAddCostModal';
import { MExpenseAddGroupModal } from './MExpenseAddGroupModal';
import { MessageModal } from '../../Modals/MessageModal';
import { MExpenseEditCostModal } from './MExpenseEditCostModal';
import { MExpenseMonthSelectorModal } from './Modals/MExpenseMonthSelectorModal';
import { MExpenseMonthEditModal } from './Modals/MExpenseMonthEditModal';

export class MExpense extends React.Component {
    columnTitleStyle = {
        fontSize: "0.9em",
        margin: "auto"
    }
    columnHeaderStyle = {
        border: "1px solid #666",
        backgroundColor: '#666',
        color: 'white',
        fontWeight: "bold",
        borderRadius: '12px 0 0 0',
        padding: "1px 0 1px 0",
        display: "flex"
    }
    constructor(props) {
        super(props);

        this.state = {
            board: {}, selectMonthMode: false, editMonthMode: false, isLoaded: false, groupMenuOpened: false, costMenuOpened: false, costItemMenuOpened: false, groups: [],
            costs: [], selectedGroup: {}, editGroup: {}, isDeleteBoardOpen: false, boardMenuOpened: false, isEditCostOpen: false, selectedCost: {}, isCostLoaded: false, isAddCostOpen: false, isAddGroupOpen: false, isDeleteGroupOpen: false
        };
        this.selectedStamp = React.createRef();
    }
    componentDidMount() {        
        this.getAsync();
    }
    async getAsync(id) {

        var json = await ModuleApi.expense.get(id);
        if (!json.ok) {
            this.setState({ error: json.message, isLoaded: false, selectedCost: {} });
        }
        else {
            this.setState({
                board: json.board,
                groups: json.groups,
                costs: json.costs,
                isLoaded: true,
                selectMonthMode: false,
                editMonthMode: false,
                selectedCost: {}
            });
        }
    }
    selectGroup = async (obj) => {
        if (this.state.selectedGroup.id === obj.id)
            obj = {};
        this.setState({ selectedGroup: obj, selectedCost: {} });
    }
    selectCost = async (obj) => {
        console.log('selectCost', obj);
        this.setState({ selectedCost: obj });
    }
    selectCostId = async (id) => {
        console.log('selectCostId', id);
        var obj = this.state.costs.find(c => c.id === id);
        this.selectCost(obj);
    }
    addCost = async (groupid, name, plan) => {
        var res = await ModuleApi.expense.mergeCost(0, groupid, name, plan, false);
        if (res.ok) {
            this.setState({ isAddCostOpen: false });
            await this.getAsync(this.state.board.id);
            this.selectCostId(res.id);
        }
    }
    editCost = async (name, plan, isclosed) => {
        var cost = this.state.selectedCost;
        if (cost === undefined)
            return;

        var res = await ModuleApi.expense.mergeCost(cost.id, cost.groupId, name, plan, isclosed);
        if (res.ok) {
            this.setState({ isEditCostOpen: false});
            this.getAsync(this.state.board.id);
            this.selectCost(cost);
        }
    }
    refreshCostCurrentValue = async (id) => {
        var json = await ModuleApi.expense.cost(id);
        if (json.ok) {
            var costs = this.state.costs;
            var c = costs.find(c => c.id === id);
            console.log('refreshCostCurrentValue', c, json.content);
            c.currentMoney = json.content.currentMoney;
            this.setState({ costs: costs });
        }
    }
    mergeGroup = async (name) => {
        var res = await ModuleApi.expense.mergeGroup(this.state.board.id, this.state.editGroup.id, name);
        if (res.ok) {
            this.setState({ isAddGroupOpen: false });
            this.getAsync(this.state.board.id);
        }
    }
    mergeBoard = async (money, prevMoney) => {
        var res = await ModuleApi.expense.mergeBoard(this.state.board.id, money, prevMoney);
        if (res.ok) {
            this.getAsync(this.state.board.id);
        }
    }
    //editGroup = async (name) => {
    //    if (this.state.selectedGroup.id === undefined)
    //        return;

    //    var res = await ModuleApi.expense.editGroup(this.state.selectedGroup.id, name);
    //    if (res.ok) {
    //        this.setState({ isAddGroupOpen: false });
    //        this.getAsync(this.state.board.id);
    //    }
    //}
    deleteGroup = async () => {
        var res = await ModuleApi.expense.deleteGroup(this.state.selectedGroup.id);
        if (res.ok) {
            this.setState({ isDeleteGroupOpen: false });
            this.getAsync(this.state.board.id);
        }
    }
    deleteBoard = async () => {
        var res = await ModuleApi.expense.deleteBoard(this.state.board.id);
        if (res.ok) {
            this.setState({ isDeleteBoardOpen: false });
            this.getAsync();
        }
    }
    showDelete = async (group) => {
        if (this.state.selectedGroup.id === undefined)
            return;
        this.setState({ isDeleteGroupOpen: true });
    }
    //event.preventDefault();

    render() {
        var boardStamp = new Date(Date.parse(this.state.board.stamp));
        var removeGroupText = '';
        if (this.state.selectedGroup.id !== undefined) {
            removeGroupText = 'Remove group: ' + this.state.selectedGroup.name+'?';
        }
        var monthImg = 'winter1';
        var monthcolor = '#009feb';
        var m = boardStamp.getMonth();
        if (m >= 2 && m <= 4) {
            monthImg = 'spring1';
            monthcolor = '#ff6f6c';
        }
        else if (m >= 5 && m <= 7) {
            monthImg = 'summer1';
            monthcolor = '#ffa032';
        }
        else if (m >= 8 && m <= 10) {
            monthImg = 'autumn1';
            monthcolor = '#ff8450';
        }
        var groupMoney = this.state.groups.reduce((a, b) => a + b.planMoney, 0)
        var extraBadMoney = this.state.groups.reduce((a, b) => a + ((b.currentMoney > b.planMoney) ? b.currentMoney - b.planMoney : 0), 0)
        if (extraBadMoney > 0) {
            extraBadMoney = "+" + extraBadMoney;
        }
        else
            extraBadMoney = "";
        var sumMoney = this.state.board.mainMoney + this.state.board.prevMoney        
        return (
            <div className="MExpense">
                {!this.state.isLoaded &&
                    <div>
                        <h2>Daily Expenses</h2>
                        <div>{this.state.error ?? 'Please wait...'}</div>
                    </div>
                }

                {this.state.isLoaded &&
                    <div id="expensesnav">
                        <h2 className="modulename" style={{ justifyContent: 'center' }}><img alt="expense" src="./images/m.expense.icon.png" height="32" /><span>Daily Expenses</span></h2>
                    <div style={{ display: 'flex' }}>
                    <h2 id="monthname">
                        <div className="MExpenseCostEditViewItem" >
                            <small style={{ color: monthcolor }}>
                                <img alt="" src={'./images/' + monthImg + '.png'} height='24' style={{ marginBottom: "4px" }} /> {boardStamp.toLocaleDateString('en-EN', { month: 'long' }).toUpperCase()}, {boardStamp.getFullYear()}
                            </small>
                            <div style={{ fontSize: "0.5em", marginTop: "-6px" }}>                                
                                <small>Money on month: <b style={{ color: sumMoney < groupMoney ? "#dc3545" : "" }}>{sumMoney} (<i>{this.state.board.prevMoney}</i>)</b>, Plan: <b>{groupMoney} {extraBadMoney && <span>(<i style={{ color: "#dc3545" }}>{extraBadMoney}</i>)</span>}</b></small>
                            </div>
                    </div>
                    
                        <MExpenseMonthSelectorModal isOpen={this.state.selectMonthMode}
                            board={this.state.board}
                            onCancel={() => this.setState({ selectMonthMode: false})}
                            onSelect={(id) => this.getAsync(id)}
                        />   

                        <MExpenseMonthEditModal isOpen={this.state.editMonthMode}
                            board={this.state.board}
                            onCancel={() => this.setState({ editMonthMode: false })}
                            onOk={(m, p) => {
                                this.mergeBoard(m, p)
                            }
                            }
                        />   
                    </h2>
                    <div style={{
                        display: "flex",
                        marginLeft: "auto", alignItems: "center"
                    }}>
                        <Dropdown  
                        toggle={() => this.setState({ boardMenuOpened: !this.state.boardMenuOpened })}
                        isOpen={this.state.boardMenuOpened}
                    >
                            <DropdownToggle style={{ marginLeft: "4px", marginTop: "6px", padding: "2px", border: "0", color: "#444", backgroundColor: 'transparent' }} >&bull;&bull;&bull;</DropdownToggle>
                            <DropdownMenu style={{left: 'auto', right: '0'}}>
                                <DropdownItem onClick={() => this.setState({ selectMonthMode: true })}>Select</DropdownItem>
                                <DropdownItem onClick={() => this.setState({ editMonthMode: true })}>Edit</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.setState({ isDeleteBoardOpen: true })}>Delete</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <MessageModal key='MRemB' isOpen={this.state.isDeleteBoardOpen}
                            title={'Remove board'}
                            message={'Remove this board?'}
                            onOk={() => this.deleteBoard()}
                            onCancel={() => this.setState({ isDeleteBoardOpen: false })}
                        />
                        </div>
                    </div>
                    </div>
                }
                
                {this.state.isLoaded &&

                    <div className="expenseMain" >
                        <div className="expenseGroups">
                        <div style={this.columnHeaderStyle}>
                            <span style={this.columnTitleStyle}>GROUPS</span>
                            <Dropdown
                                toggle={() => this.setState({ groupMenuOpened: !this.state.groupMenuOpened })}
                                isOpen={this.state.groupMenuOpened}
                            >
                                <DropdownToggle style={{ marginRight: "8px", marginBottom: "2px", padding: "0 6px", border: "0", backgroundColor: 'transparent' }} >&bull;&bull;&bull;</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => this.setState({ isAddGroupOpen: true, editGroup: { id: 0 } })}>Add</DropdownItem>
                                    {this.state.selectedGroup.id !== undefined && <DropdownItem onClick={() => this.setState({ isAddGroupOpen: true, editGroup: this.state.selectedGroup })}>Edit</DropdownItem>}
                                    {this.state.selectedGroup.id !== undefined && <DropdownItem divider />}
                                    {this.state.selectedGroup.id !== undefined && <DropdownItem onClick={() => this.showDelete()}>Delete</DropdownItem>}
                                </DropdownMenu>
                            </Dropdown>
                            <MExpenseAddGroupModal isOpen={this.state.isAddGroupOpen}
                                value={this.state.editGroup.name}
                                onOk={(name) => this.mergeGroup(name)}
                                onCancel={() => this.setState({ isAddGroupOpen: false })}
                            />
                            <MessageModal key='MRemG' isOpen={this.state.isDeleteGroupOpen}
                                title={'Remove group'}
                                message={removeGroupText}
                                onOk={() => this.deleteGroup()}
                                onCancel={() => this.setState({ isDeleteGroupOpen: false })}
                            />
                        </div>
                        {this.state.groups.map(q => {
                            return <MExpenseGroupView key={q.id} content={q} isSelected={this.state.selectedGroup.id === q.id} onSelect={(obj) => this.selectGroup(obj)} />
                        }
                        )}                  
                    </div>
                    <div className="expenseCosts">
                        <div style={this.columnHeaderStyle}>
                            <span style={this.columnTitleStyle}>COSTS</span>
                            {(this.state.groups.length > 0 || this.state.selectedCost.id !== undefined) &&
                                <Dropdown
                                    toggle={() => this.setState({ costMenuOpened: !this.state.costMenuOpened })}
                                    isOpen={this.state.costMenuOpened}>
                                    <DropdownToggle style={{ marginRight: "8px", marginBottom: "2px", padding: "0 6px", border: "0", backgroundColor: 'transparent' }} >&bull;&bull;&bull;</DropdownToggle>
                                <DropdownMenu style={{ right: '0', left: 'auto' }}>
                                        {this.state.groups.length > 0 && <DropdownItem onClick={() => this.setState({ isAddCostOpen: true })}>Add</DropdownItem>}
                                        {this.state.selectedCost.id !== undefined && <DropdownItem onClick={() => this.setState({ isEditCostOpen: true })}>Edit</DropdownItem>}
                                        {this.state.selectedCost.id !== undefined && <DropdownItem divider />}
                                        {this.state.selectedCost.id !== undefined && <DropdownItem onClick={() => this._deleteCost()}>Delete</DropdownItem>}
                                    </DropdownMenu>
                                </Dropdown>}

                            <MExpenseAddCostModal isOpen={this.state.isAddCostOpen}
                                selectedGroup={this.state.selectedGroup}
                                items={this.state.groups}
                                onOk={(groupid, name, plan) => this.addCost(groupid, name, plan)}
                                onCancel={() => this.setState({ isAddCostOpen: false })}
                            />

                            <MExpenseEditCostModal isOpen={this.state.isEditCostOpen}
                                content={this.state.selectedCost}
                                onOk={(id, name, plan, isclosed) => this.editCost(id, name, plan, isclosed)}
                                onCancel={() => this.setState({ isEditCostOpen: false })}
                            />
                        </div>
                        {this.state.costs.filter(a => this.state.selectedGroup.id === undefined
                            || a.groupId === this.state.selectedGroup.id).map(q => {
                                return <MExpenseCostView key={q.id} content={q} isSelected={this.state.selectedCost.id === q.id} onSelect={(obj) => this.selectCost(obj)} />
                        }
                        )}
                        
                    </div>
                    <div className="expenseCostDetail">

                        <div style={this.columnHeaderStyle}>
                            <span style={this.columnTitleStyle}>ACTIVE COST</span>
                            {this.state.selectedCost.id > 0 &&
                                <Dropdown
                                    toggle={() => this.setState({ costItemMenuOpened: !this.state.costItemMenuOpened })}
                                    isOpen={this.state.costItemMenuOpened}>
                                    <DropdownToggle style={{ marginRight: "8px", marginBottom: "2px", padding: "0 6px", border: "0", backgroundColor: 'transparent' }} >&bull;&bull;&bull;</DropdownToggle>
                                <DropdownMenu style={{ right: '0', left: 'auto'}}>
                                    <DropdownItem onClick={() => this._showAdd()}>Add purchase</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }
                        </div>
                        {this.state.selectedCost.id === undefined && 
                            <span>Please select any cost</span>}
                        {this.state.selectedCost.id !== undefined &&
                            <MExpenseCostEditView setClick={action => { this._showAdd = action }}
                            addDeleteAction={action => { this._deleteCost = action }}
                            afterAnyAction={async (name) => 
                            {
                                //if (name === 'delete') {
                                //    this.setState({ selectedCost: {}});
                                //}
                                var id = this.state.selectedCost.id;
                                await this.getAsync(this.state.board.id);
                                if (name !== 'delete') {
                                    this.selectCostId(id);
                                }
                            }} id={this.state.selectedCost.id} />
                        }
                        
                    </div>
                </div>
                }
                
            </div>
            )
    }
}