export class SettingsApi {
    static handleError = function (err) {
        console.warn(err);
        return new Response(JSON.stringify({
            ok: false,
            code: 400,
            message: 'Network Error'
        }));
    };

    static save = async (fname, sname, lname) => {
        var response = await (fetch('api/Account/save', {
            method: 'POST', // или 'PUT'
            body: JSON.stringify({ fname, sname, lname }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(SettingsApi.handleError));
        if (response.ok) {
            if (response.redirected)
                return window.location = '/';
            return response.json();
        } else {
            return Promise.reject(response);
        }
    }
    static get = async () => {
        var response = await (fetch('api/Account/get').catch(SettingsApi.handleError));
        if (response.ok) {
            if (response.redirected)
                return window.location = '/';
            return response.json();
        } else {
            return Promise.reject(response);
        }
    }
}