import React from 'react';
import { Link } from 'react-router-dom';
import { Input, InputGroup, Label, Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap';
import { Agreement } from './Agreement';
import './registerpartialview.css';

export class RegisterPartialView extends React.Component {
    formStyle = {
        textAlign: "center",
        alignSelf: "start",
        justifySelf: "center",
        width: "240px",
        display: "grid",
        gridRow: "1 / span 2",
        height: "auto",
        gridTemplateRows: "auto auto auto auto auto"

    }
    blockStyle = {
        backgroundColor: "white",
        borderRadius: "10px",
        display: "flex",
        border: " solid 1px #444",
        height: "auto",
        marginBottom: "8px",
        padding: "4px"
    }
    inputStyle = {
        backgroundColor: "transparent",
        fontSize: "0.8em",
        marginLeft: "4px",
        border: "none",
        outline: "none",
        alignItems: "center"
    }
    imgBlockStyle = {
        width: "24px",
        display: "flex",
        height: "auto"
    }
    constructor(props) {
        super(props);

        this.state = { agreement: false, showagreement: false };

        this.email = React.createRef();
        this.password = React.createRef();
    }
    validateEmail = (email) => {
        var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(email);
    }
    submit = (event) => {
        event.preventDefault();
        if (this.validateEmail(this.email.current.value)) {
            this.props.confirm(this.email.current.value, this.password.current.value);
        }
        else {
            console.log('incorrect email format');
        }
    }
    render() {
        return (
            <div className="registerPageMain">
                <div className="registerPartLeftBack" />
                <div className="registerPartLeftLogo">
                    <Link className="logo_link2" to="/" />
                </div>
                <div className="registerPartLeftLogin" >
                    <div style={{ margin: "0 auto", height: "auto", display: "grid", gridTemplateRows: "1fr 1fr" }}>
                        {!this.props.confirmed
                            && <form style={this.formStyle} onSubmit={this.submit}>
                                <div style={{ marginBottom: "10px" }}>
                                    <span style={{ display: "block", fontSize: "1.3em", marginBottom: "-8px" }}>REGISTER FORM</span>
                                    <span style={{ fontSize: "0.9em" }}>TO ACCESS THE PORTAL</span>
                                </div>
                                <div style={this.blockStyle} >
                                    <div style={this.imgBlockStyle}>
                                        <img alt="email" srcSet="/images/emailIcon.png" style={{ height: "12px", margin: "auto" }} />
                                    </div>
                                    <input placeholder="Enter Email" type="email" ref={this.email} style={this.inputStyle} />
                                </div>
                                <div style={this.blockStyle} >
                                    <div style={this.imgBlockStyle}>
                                        <img alt="lock" srcSet="/images/lockIcon.png" style={{ height: "14px", margin: "auto" }} />
                                    </div>
                                    <input placeholder="Enter Security Code" ref={this.password} type="password" style={this.inputStyle} />
                                </div>
                                <InputGroup style={{ marginBottom: "8px", fontSize: "0.8em", marginLeft: "1.25rem" }}>
                                    <Input type="checkbox" value={this.state.agreement}
                                        onChange={(e) => this.setState({ agreement: !this.state.agreement })} /> <Label>I agree with <Link to="/" onClick={() => this.setState({ showagreement: true })}>Terms and Agreement</Link></Label>

                                    <Modal toggle={() => this.setState({ showagreement: false })} isOpen={this.state.showagreement} aria-labelledby="contained-modal-title-vcenter" size="lg" style={{ height: "auto" }}>
                                        <ModalHeader>Terms and Agreement</ModalHeader>
                                        <ModalBody>
                                            <Agreement />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={() => this.setState({ showagreement: false })}>Close</Button>
                                        </ModalFooter>
                                    </Modal>

                                </InputGroup>
                                <input type="submit" disabled={!this.state.agreement} className="btn btn-primary" style={{ height: "auto" }} value="Register" />
                            </form>}
                        {this.state.confirmed && <div>Please check your Email</div>}
                    </div>
                </div>
                <div className="registerPartCenterImage">
                    <img alt="" src="/images/imageim2.png" style={{ height: "500px", width: "100px" }} />
                </div>
                <div className="registerPartRightBack" />

                <div className="registerPartRightText" >
                    <b>REGISTRATION !</b>
                    <span style={{ fontSize: "1.1em" }}>Enter your Email and Security Code<br />
                                Press <Link to="#" style={{ color: "#444", fontWeight: "bold" }} onClick={() => this.props.showView('login')}>Sign in</Link> if you have an account
                        </span>
                </div>

                <div className="registerPartRightBottom">
                    <div>Idea and development: AQL.exe (<a href="mailto:admin@grootplek.com">write me</a>)</div>
                </div>
            </div>
            )
    }
}