import React from 'react';
import { Link } from 'react-router-dom';

export class MExpenseCostItemView extends React.Component {
    style = {
        fontSize: "0.9em",
        border: "1px solid lightgray",
        borderTop: "0",
        borderLeft: "0",
        borderRight: "0",
        padding: "2px 4px",
        display: "flex",
        minWidth: '150px',
        gap: "10px"
    }
    statusStyle = {       
        margin: "0 4px 0 -2px",
        width: "2px"
    }
    
    render() {
        var d = new Date( Date.parse(this.props.content.stamp));
        return (
            <div className="MExpenseCostItemView" style={this.style} >

                <div style={{ display: "flex", background: 'url(./images/datetempl.png) no-repeat', backgroundSize: "20px 20px", width: "20px" }}>
                    <div style={{ margin: "auto", fontSize: "10px", marginTop: "5px", color: "#3481B5", fontWeight: "bold", opacity: "0.9" }}>{d.getDate()}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 'auto' }}><b>{this.props.content.value}</b> <small>{this.props.content.note}</small></div>
                </div>

                <div style={{ marginLeft: 'auto' }}><Link to='#' onClick={() => this.props.onDelete()}>x</Link></div>
            </div>
        )
    }
}