import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';

export class MessageModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: props.title,
            message: props.message,
            type: props.type,
            isOpen: false
        };
    }
    componentWillReceiveProps(props) {
        this.setState({
            isOpen: props.isOpen,
            title: props.title,
            message: props.message,
            type: props.type
        });
    }
    onOk = () => {
        this.props.onOk();
    }
    onCancel = () => {
        this.props.onCancel();
    }

    render() {
        return (
            <Modal toggle={() => this.onCancel()}
                isOpen={this.state.isOpen}
                aria-labelledby="contained-modal-title-vcenter" size="sm" >
                <ModalHeader>{this.state.title}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>{this.state.message}</Label>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => this.onOk()}>OK</Button>
                    <Button color="secondary" onClick={() => this.onCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}