import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';

export class MExpenseMonthEditModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen,
            board: {}
        };
        this.mainMoney = React.createRef();
        this.prevMoney = React.createRef();
    }
    componentWillReceiveProps(props) {
        this.setState({ isOpen: props.isOpen, board: props.board ?? {} });
    }
    onCancel = () => {
        this.props.onCancel();
    }
    onOk = () => {
        this.props.onOk(parseFloat(this.mainMoney.current.value), parseFloat(this.prevMoney.current.value));
    }

    render() {
        var boardStamp = new Date();
        if (this.state.board.id)
            boardStamp = new Date(Date.parse(this.state.board.stamp));
        console.log(boardStamp);       
        return (
            <Modal toggle={() => this.onCancel()}
                isOpen={this.state.isOpen}
                aria-labelledby="contained-modal-title-vcenter" size="sm" >
                <ModalHeader>Edit Board {boardStamp.toLocaleDateString('en-EN', { month: 'long' })}, {boardStamp.getFullYear()}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Money</Label>
                        <Input innerRef={this.mainMoney} defaultValue={this.state.board.mainMoney} autoFocus />
                    </FormGroup>
                    <FormGroup>
                        <Label>Previous</Label>
                        <Input innerRef={this.prevMoney} defaultValue={this.state.board.prevMoney}/>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => this.onOk()}>Ok</Button>
                    <Button color="secondary" onClick={() => this.onCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}