import React from 'react';
import { AuthGet } from './Auth';
import { Link } from 'react-router-dom';
import { Route } from 'react-router';

export class Login extends React.Component {
    loginStyle = {
        alignSelf: "center !important",
        justifySelf: "right",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        gridRowGap: "10px",
        gridColumnGap: "10px",
    }
    buttonStyle = {
        marginLeft: "4px"
    }


    constructor(props) {
        super(props);
        this.state = { name: AuthGet().name };
    }
    
    logout = (event) => {
        fetch('api/Account/logout')
            .then(r => r.json())
            .then(a => {
                if (a.ok)
                    window.location = '/';
            });        
    }
    render() {
        let isHomePage = (val) => val === '/' || val === '/m.password' || val === '/m.expense' || val === '/m.games';
        let isSettingsPage = (val) => val === '/settings';
        return (
            <div style={this.loginStyle} id="login">                               
                <Route children={(props) => 
                    <div style={{ display: "flex", gap: "10px" }}>
                        <div className="menu">
                            <Link to='/' className={isHomePage(props.location.pathname)  ? "selected" : ""} > MY PAGE</Link>
                        </div>
                        <div className="menu">
                            <Link to='/settings' className={isSettingsPage(props.location.pathname) ? "selected" : ""}>SETTINGS</Link>
                        </div>
                    </div>
                } />
                <div className="hellotext">{this.state.name} [ <Link to="#" onClick={this.logout}>Exit</Link> ]</div>
            </div>
        )
    }
}
