import React from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label  } from 'reactstrap';

export class MExpenseAddCostItemModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: this.props.isOpen, selectedDay: 0, currentDay: 1, startIndex: 1 };
        this.value = React.createRef();
        this.note = React.createRef();

        this.state.items = this.generateItems(1);
    }
    generateItems = (start) => {
        const count = 4;
        var items = Array(count).fill().map((_, idx) => {
            return {caption: start + idx}
        })
        return items;
    }
    daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    } 
    componentWillReceiveProps(props) {
        var dd = new Date(Date.parse(props.boardPeriod.substr(0, 10)));
        var curr = new Date();
        var mm = this.daysInMonth(dd.getMonth() + 1, dd.getFullYear());
        var currDay = curr.getDate();
        var startDay = Math.ceil(currDay / 3) * 3 - 2;
        if (currDay === 1)
            startDay = 1;
        if (startDay + 3 > mm)
            startDay = mm - 3;
        this.setState({
            isOpen: props.isOpen, items: this.generateItems(startDay),
            currentDay: currDay, selectedDay: currDay, startIndex: startDay, monthDays: mm,
            monthStamp: dd
        });
    }
    onOk = () => {
        if (!this.value.current.value)
            return;
        var stamp = new Date(this.state.monthStamp);
        stamp.setDate(this.state.selectedDay);        
        this.props.onOk(stamp, parseFloat(this.value.current.value), this.note.current.value);
    }
    onCancel = () => {
        this.props.onCancel();
    }  
    previous = () => {
        if (this.state.startIndex === 1)
            return;
        let nextIndex = this.state.startIndex - 3;
        if (nextIndex < 1)
            nextIndex = 1;
        this.setState({ startIndex: nextIndex, items: this.generateItems(nextIndex) });
    }
    next = () => {
        if (this.state.startIndex + 3 === this.state.monthDays)
            return;

        let nextIndex = this.state.startIndex + 3;        
        if (nextIndex + 3 > this.state.monthDays)
            nextIndex = this.state.monthDays - 3;
        this.setState({ startIndex: nextIndex, items: this.generateItems(nextIndex) });
    }
    render() {
        return (            
            <Modal toggle={() => this.onCancel()}
                isOpen={this.state.isOpen}
                aria-labelledby="contained-modal-title-vcenter" size="sm" >
                <ModalHeader>Add Purchase</ModalHeader>
                <ModalBody>
                    <FormGroup>                        
                        <Label>Day of Month</Label>
                        <div style={{ display: "flex", gap: "5px", width: "100%" }}>
                            <Button onClick={this.previous}>&lt;</Button>
                            {this.state.items.map((item) => {
                                return (
                                    <Button key={item.caption} style={{ flex: "1" }}
                                        onClick={() => this.setState({ selectedDay: item.caption })}
                                        color={this.state.selectedDay === item.caption ? 'success' : (this.state.currentDay === item.caption ? 'secondary' : '')}
                                        outline={this.state.selectedDay === item.caption ? false : true} size="sm">
                                        {item.caption}
                                    </Button>
                                );
                            })}
                            <Button onClick={this.next}>&gt;</Button>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Value</Label>
                        <Input innerRef={this.value} autoFocus />
                    </FormGroup>
                    <FormGroup>
                        <Label>Note</Label>
                        <Input innerRef={this.note} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => this.onOk()}>OK</Button>
                    <Button color="secondary" onClick={() => this.onCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}