import React, { Component } from 'react';
import { Auth } from './Auth';
import { Label, FormGroup, Input, Col } from 'reactstrap';
import { SettingsApi } from './api/SettingsApi';

export class Settings extends Component {
    static displayName = Settings.name;

  constructor(props) {
    super(props);
      this.state = { uname: '', fname: '', sname: '', lname: '', message: '' };
    }

    componentDidMount() {
        this.loadContent();
    }
    loadContent = async () => {
        const json = await SettingsApi.get();
        this.setState({ ...json });
    }
    save = async () => {
        const json = await SettingsApi.save(this.state.fname, this.state.sname, this.state.lname);
        if (json.ok) {
            window.location.reload();
        }
        else {
            this.setState({ message: json.message });
        }
    }
  render() {
      return (
          <Auth only>
            <div>
                  <h1>Settings</h1>

                  <Col style={{ height: "auto" }}>
                      <FormGroup>
                          <Label for='uname'>User Name</Label>
                          <Input id='uname' defaultValue={this.state.uname} disabled />
                      </FormGroup>
                          <FormGroup>
                          <Label for='fname'>First Name</Label>
                          <Input id='fname' value={this.state.fname} onChange={(v) => this.setState({ [v.currentTarget.id]: v.currentTarget.value })} />
                          </FormGroup>
                          <FormGroup>
                              <Label for='sname'>Second Name</Label>
                          <Input id='sname' value={this.state.sname} onChange={(v) => this.setState({ [v.currentTarget.id]: v.currentTarget.value })}/>
                          </FormGroup>
                          <FormGroup>
                              <Label for='lname'>Last Name</Label>
                          <Input id='lname' value={this.state.lname} onChange={(v) => this.setState({ [v.currentTarget.id]: v.currentTarget.value })}/>
                          </FormGroup>
                      </Col>
                

                  <button className="btn btn-primary" onClick={this.save}>Save</button>
                  <div style={{color: 'blue'}}>{this.state.message}</div>
              </div>
          </Auth>
    );
  }
}
