import React, { Component } from 'react';
import { Auth } from './Auth';
import { Link } from 'react-router-dom';
import md5 from 'md5';
import { LoginPartialView } from './LoginPartialView';
import { RegisterPartialView } from './RegisterPartialView';
import { LoginApi } from './api/LoginApi';
import { ModuleApi } from './api/ModuleApi';

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { name: '', modules: [], view: "login", confirmed: false };

        this.password = React.createRef();
        this.email = React.createRef();
    }
    componentDidMount() {
        this.loadContent();
    }
    async loadContent() {
        var json = await ModuleApi.load();
        this.setState({ modules: json.modules });
    }
    async login(l, p) {
        var json = await LoginApi.login(l, md5(p));
        if (json.ok)
            window.location.reload();
    }
    async register(l, code) {
        var json = await LoginApi.register(l, code);
        if (json.ok)
            this.setState({ confirmed: true });
        else
            this.setState({ confirmed: false });
    }
    showView = (name) => {
        this.setState({ view: name });
    }
    render() {
        return (
            <Auth>
                <div className="home">
                    <div style={{ gridColumn: "2" }}>
                    {this.state.view === "login"
                        && <LoginPartialView login={(l, p) => this.login(l, p)} showView={(v) => this.showView(v)} />}

                    {this.state.view === "register"
                        && <RegisterPartialView confirmed={this.state.confirmed} confirm={(l, p) => this.register(l, p)} showView={(v) => this.showView(v)} />}
                    </div>
                </div>
                <div className="moduleList">
                    {this.state.modules.map(a => {
                        return <div key={a.name} className={"gridcard " + a.link.replace('.', '') + (!a.isEnabled ? " disabled" : "")}>
                            <h3 className="card-title"><img alt="Icon" src={'/images/' + a.link + '.icon.png'} height="24" style={{ marginBottom: "4px" }} /> {a.name}</h3>
                            <p style={{ alignSelf: "start" }}><span dangerouslySetInnerHTML={{ __html: a.description }} /></p>
                            {a.isEnabled && <Link to={a.link}>{a.isActive ? "Open" : "Include and Open"}</Link>}
                            {!a.isEnabled && <span style={{ color: "black" }}>inactive</span>}
                        </div>
                    })}
                </div>
            </Auth>
        );
    }
}