export class LoginApi {

    handleError = function (err) {
        console.warn(err);
        return new Response(JSON.stringify({
            ok: false,
            code: 400,
            message: 'Network Error'
        }));
    };

    static login = async (email, pass) => {
        var response = await (fetch('api/Account/login', {
            method: 'POST', 
            body: JSON.stringify({ email: email, pass: pass }), 
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(this.handleError));
        if (response.ok) {
            if (response.redirected)
                return window.location = '/';
            return response.json();
        } else {
            return Promise.reject(response);
        }
    }
    static register = async (email, code) => {
        const response = await (fetch('api/Account/register', {
            method: 'POST',
            body: JSON.stringify({ email: email, pass: code }), 
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(this.handleError));
        if (response.ok) {
            if (response.redirected)
                return window.location = '/';
            return response.json();
        } else {
            return Promise.reject(response);
        }           
    }
}