import React from 'react';
import { FormGroup, Button } from 'reactstrap';
import { MExpenseAddCostItemModal } from './MExpenseAddCostItemModal';
import { ModuleApi } from '../../api/ModuleApi';
import { MExpenseCostItemView } from './MExpenseCostItemView';
import { MExpenseGraph } from './MExpenseGraph';
import { MessageModal } from '../../Modals/MessageModal';

export class MExpenseCostEditView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, content: {}, editNameMode: false, editPlanMode: false, editCloseMode: false, editOftenUsedMode: false,
            isAddOpen: false,
            isDeleteOpen: false,
            deleteItem: undefined
        };
       
    }
    componentWillMount() {
        this.refresh(this.props.id);
        this.props.setClick(this.showAdd);
        this.props.addDeleteAction(this.showDelete);
    }
    componentWillReceiveProps(props) {
        this.setState({ editNameMode: false, editPlanMode: false, editCloseMode: false });
        if (props && (props.id !== this.state.content.id || this.state.isLoading === true)) {
            this.refresh(props.id);            
        }
    }
    showAdd= () => {
        this.setState({ isAddOpen: true });
    }
    showDelete = () => {
        this.setState({ isDeleteOpen: true });
    }
    style = {
        fontSize: "0.9em",
        padding: "2px 4px 2px 0",
        display: "flex",
        flexDirection: 'column',
        minWidth: '70px'
    }
    statusStyle = {       
        margin: "0 4px 0 -2px",
        width: "2px"
    }
    addPurchase = async (stamp, val, note) => {
        var res = await ModuleApi.expense.addPurchase(this.props.id, stamp, val, note);
        if (res.ok) {
            this.setState({ isLoading: true, isAddOpen: false, isDeleteOpen: false, deleteItem: undefined });
            this.props.afterAnyAction('addpurchase');
            // this.refresh(this.props.id);
        }
    }
    deleteCost = async () => {
        var res = await ModuleApi.expense.deleteCost(this.state.content.id);
        if (res.ok) {
            this.setState({ isLoading: true, isAddOpen: false, isDeleteOpen: false, deleteItem: undefined });
            this.props.afterAnyAction('delete');
        }
    }
    deleteCostItem = async () => {
        var res = await ModuleApi.expense.deleteCostItem(this.state.deleteItem.id);
        if (res.ok) {
            this.setState({ isLoading: true, isAddOpen: false, isDeleteOpen: false, deleteItem: undefined  });
            this.props.afterAnyAction('deleteitem');
        }
    }
    refresh = async (id) => {
        this.setState({ isLoading: true, isAddOpen: false, isDeleteOpen: false, deleteItem: undefined })
        var json = await ModuleApi.expense.cost(id);
        if (json.ok) {
            this.setState({ content: json.content, isLoading: false })
        }
    }
  
    render() {
        const { name, groupName, planMoney, isClosed, currentMoney, items, isOftenUsed, graphItems } = this.state.content;
        var removeItemText = '';
        if (this.state.deleteItem !== undefined) {
            var d = new Date(Date.parse(this.state.deleteItem.stamp));
            removeItemText = this.state.deleteItem.value +' for '+ d.getDate() + ' ' + d.toLocaleString('default', { month: 'long' });
        }
        let currentMoneyClass = '';
        if (currentMoney > planMoney) {
            currentMoneyClass = 'text-danger font-weight-bold';
        }
        return (
            <div className="MExpenseCostEditView" >
                {this.state.isLoading && <div>Please wait...</div>}
                {!this.state.isLoading && <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
                    <div style={this.style} >
                        <div className="MExpenseCostEditViewItem" style={{ fontSize: "1.2em" }}><small>Name:</small> {groupName} / <b>{name}</b></div>
                        <div className="MExpenseCostEditViewItem" style={{ fontSize: "1.2em" }}><small>Expense Plan:</small> <span className={currentMoneyClass}>{currentMoney}</span> / <b>{planMoney}</b></div>                        
                        <div className="MExpenseCostEditViewItem" style={{ fontSize: "1.2em" }}><small>Status:</small> <b>{isClosed ? <span className='text-success'>closed</span> : <span>open</span>}</b></div>
                        <div className="MExpenseCostEditViewItem" style={{ fontSize: "1.2em" }}><small>Favourite:</small> <b>{isOftenUsed ? <span className='text-success'>yes</span> : <span>no</span>}</b></div>                       
                        <FormGroup>
                            <MExpenseGraph data={graphItems} />
                        </FormGroup>

                        <FormGroup>
                            <Button color="primary" size="sm" onClick={() => this.showAdd()}>Add purchase</Button>
                            <MExpenseAddCostItemModal isOpen={this.state.isAddOpen}
                                boardPeriod={this.state.content.boardPeriod}
                                onOk={(stamp, val, note) => this.addPurchase(stamp, val, note)}
                                onCancel={() => this.setState({ isAddOpen: false})}
                            />
                            <MessageModal key='MRemC' isOpen={this.state.isDeleteOpen}
                                title={'Remove cost'}
                                message={'Remove: ' + this.state.content.name + ' and its ' + (items.length ?? '0') + ' items?'}
                                onOk={() => this.deleteCost()}
                                onCancel={() => this.setState({ isDeleteOpen: false })}
                            />
                            <MessageModal key='MRemCI' isOpen={this.state.deleteItem !== undefined}
                                title={'Remove cost item'}
                                message={'Remove item: ' + removeItemText + '?'}
                                onOk={() => this.deleteCostItem()}
                                onCancel={() => this.setState({ deleteItem: undefined })}
                            />
                        </FormGroup>
                    </div>
                    <div>
                        {items.map(q => {

                            return <MExpenseCostItemView key={q.id} content={q} onDelete={() => this.setState({ deleteItem: q })} />
                        })}
                    </div>
                </div>}
            </div>
        )
    }
}