import React from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, InputGroup } from 'reactstrap';

export class MExpenseEditCostModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen, content: {} };
        this.name = React.createRef();
        this.value = React.createRef();
        this.isclosed = React.createRef();
    }
    componentWillReceiveProps(props) {
        this.setState({
            isOpen: props.isOpen,
            content: props.content
        });
    }
    onOk = () => {
        this.props.onOk(this.name.current.value, parseFloat(this.value.current.value), this.isclosed.current.checked);
    }
    onCancel = () => {
        this.props.onCancel();
    }

    render() {
        return (
            <Modal toggle={() => this.onCancel()}
                isOpen={this.state.isOpen}
                aria-labelledby="contained-modal-title-vcenter" size="sm" >
                <ModalHeader>Edit Cost</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Group</Label>
                        <Input id="caret" color="primary" readOnly value={this.state.content.groupName} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input innerRef={this.name} defaultValue={this.state.content.name} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Plan</Label>
                        <Input innerRef={this.value} autoFocus defaultValue={this.state.content.planMoney}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Status</Label>
                        <InputGroup style={{ marginLeft: "1.2em" }}>
                            <Label>Closed</Label>
                            <Input type='checkbox' innerRef={this.isclosed} defaultChecked={this.state.content.isClosed} />
                        </InputGroup>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => this.onOk()}>OK</Button>
                    <Button color="secondary" onClick={() => this.onCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}