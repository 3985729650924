import React from 'react';

export class MExpenseGroupView extends React.Component {
    style = {
        fontSize: "0.9em",
        border: "1px solid lightgray",
        margin: "-1px 0px 0px 0",
        padding: "2px 4px 2px 0",
        display: "flex"
    }
    statusStyle = {       
        margin: "0 4px 0 -2px",
        width: "2px"
    }
    render() {
        let status = '';
        if (this.props.content.isClosed) {
            status = 'bg-success';
        }
        let currentMoneyClass = '';
        if (this.props.content.currentMoney > this.props.content.planMoney) {
            currentMoneyClass = 'text-danger font-weight-bold';
        }
        let className = 'MExpenseGroupView';
        if (this.props.isSelected) {
            className += ' selected';
        }        

        return (
            <div className={className} style={this.style} onClick={() => this.props.onSelect(this.props.content)}>
                <div style={this.statusStyle} className={status} />
                <div>{this.props.content.name}</div>
                <div style={{ marginLeft: 'auto' }}>
                    <span className={currentMoneyClass}>{this.props.content.currentMoney}</span> / <span><b>{this.props.content.planMoney}</b></span>
                </div>
            </div>
        )
    }
}