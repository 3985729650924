import React from 'react';
import { ModuleApi } from '../../api/ModuleApi';
import { Table, Badge } from 'reactstrap';

export class MGames extends React.Component {
    constructor(props) {
        super(props);

        this.state = { error: '', isLoaded: false, raiting: [], gamepath: '', games: [] };
    }
    componentDidMount() {
        this.getAsync();
    }
    async getAsync() {
        var json = await ModuleApi.games.get();
        if (!json.ok) {
            this.setState({ error: json.message, isLoaded: false });
        }
        else {
            console.log(json);
            this.setState({
                raiting: json.raiting,
                gamespath: json.gamespath,
                games: json.games,
                isLoaded: true,
            });
        }
    }
    render() {
        return (
            <div className="MGames">
                {!this.state.isLoaded &&
                    <div>
                        <h2>Table Games</h2>                        
                        <div>{this.state.error ?? 'Please wait...'}</div>
                    </div>
                }

                {this.state.isLoaded &&
                    <div style={{}}>
                        <h2 className="modulename"><img alt="games" src="./images/m.games.icon.png" height="32" /><span>Table Games</span></h2>
                        <div>Available games: {this.state.games.map(c => <Badge color="primary" style={{ marginLeft: 4 }} key={c}><b>{c}</b></Badge >)}</div>
                        <div style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
                            <Table size="sm">
                                <thead>
                                    <tr>
                                        <th>Game Name</th>
                                        <th>Raiting</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.raiting.map(c => {
                                        return (
                                            <tr key={c.name}>
                                                <td>{c.name}</td>
                                                <td>{c.raiting}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                }

            </div>
        )
    }
}