import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';

export class MExpenseMonthSelectorModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen,
            board: {}
        };
    }
    componentWillReceiveProps(props) {
        this.setState({ isOpen: props.isOpen, board: props.board ?? {} });
    }
    onCancel = () => {
        this.props.onCancel();
    }
    onSelect = (boardid) => {
        this.props.onSelect(boardid);
    }

    render() {
        var boardStamp = new Date();
        if (this.state.board.id)
            boardStamp = new Date(Date.parse(this.state.board.stamp));
        console.log(boardStamp);
        var prev;
        if (this.state.board && this.state.board.previous)
            prev = new Date(Date.parse(this.state.board.previous.stamp));
        var next;
        if (this.state.board && this.state.board.next)
            next = new Date(Date.parse(this.state.board.next.stamp));
        var active;
        if (this.state.board && this.state.board.active)
            active = new Date(Date.parse(this.state.board.active.stamp));
        if ((this.state.board && this.state.board.previous && this.state.board && this.state.board.previous.isActual)
            || (this.state.board && this.state.board.next && this.state.board.next.isActual)
            || (this.state.board.id && this.state.board.isActual))
            active = undefined;

        var currentColor = 'warning';
        if (this.state.board.id && this.state.board.isActual)
            currentColor = 'success';
        var prevColor = 'warning';
        if (prev && this.state.board.previous.isActual) {
            currentColor = 'primary';
            prevColor = 'success';
        }
        var nextColor = 'warning';
        if (next && this.state.board.next.isActual) {
            nextColor = 'success';
            currentColor = 'warning';
        }
        return (
            <Modal toggle={() => this.onCancel()}
                isOpen={this.state.isOpen}
                aria-labelledby="contained-modal-title-vcenter" size="sm" >
                <ModalHeader>Select Board</ModalHeader>
                <ModalBody>
                    <ButtonGroup>
                        {this.state.board.previous &&
                            <Button color={prevColor}
                            onClick={() => this.onSelect(this.state.board.previous.id)}>
                                {prev.toLocaleDateString('en-EN', { month: 'long' })} {prev.getFullYear()}
                            </Button>
                        }
                        {this.state.board.id !== undefined &&
                            <Button color={currentColor} onClick={() => this.onSelect(this.state.board.id)} >
                                {boardStamp.toLocaleDateString('en-EN', { month: 'long' })} {boardStamp.getFullYear()}
                            </Button>
                        }
                        {this.state.board.next && this.state.board.next.id === 0 &&
                            <Button outline color="primary" style={{ marginLeft: 'auto' }} onClick={() => this.onSelect(this.state.board.next.id)}>
                                {next.toLocaleDateString('en-EN', { month: 'long' })} {next.getFullYear()}
                            </Button>
                        }
                        {this.state.board.next &&  this.state.board.next.id > 0 &&
                            <Button color={nextColor} onClick={() => this.onSelect(this.state.board.next.id)}>
                                {next.toLocaleDateString('en-EN', { month: 'long' })} {next.getFullYear()}
                            </Button>
                        }
                    </ButtonGroup>
                    {active && <Link to="#" onClick={() => this.onSelect(this.state.board.active.id)}>Go to actual {active.toLocaleDateString('en-EN', { month: 'long' })} {active.getFullYear()}</Link>}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.onCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}