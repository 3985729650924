import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './logo.css';

export class Logo extends Component {
    render() {
        return (
            <Link to="/" className="logo_link" />
        );
    }
}
