import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, ModalHeader, ModalFooter, ModalBody, Input, Label, Table } from 'reactstrap';
import { ModuleApi } from '../api/ModuleApi';

export class MPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [], editElement: {}, isLoaded: false, isNew: false, showModal: false };
        this.value = React.createRef();
        this.modelProperty = React.createRef();
        this.modelValue = React.createRef();
        this.modelGroup = React.createRef();
    }
    componentDidMount() {        
        this.getAsync();
    }
    async getAsync() {

        var json = await ModuleApi.password.get();
        this.setState({ isNew: json.isNew });
    }
    async mergeAsync(id, name, value, group) {
        var json = await ModuleApi.password.merge(id, name, value, group, this.state.guid);
        if (json.ok) {
            var dd = this.state.data;
            if (id === 0) {
                dd.push({ id: json.id, name, value, group });
            }
            else {
                var di = dd.find(c => c.id === id);
                di.name = name;
                di.value = value;
                di.group = group;
            }
            this.setState({ data: dd, showModal: false });
        }       
    }
    async removeAsync(id) {
        var json = await ModuleApi.password.delete(id, this.state.guid);
        if (json.ok) {
            var dd = this.state.data;
            dd.splice(dd.findIndex(c => c.id === id), 1);
            this.setState({ data: dd, showModal: false });
        }       
    }
    async submitAsync(p) {
        var json = await ModuleApi.password.load(p);
        if (json.ok) {
            this.setState({ isLoaded: true, data: json.data, guid: json.guid });
        }
    }

    submit = (event) => {
        this.submitAsync(this.value.current.value);
        event.preventDefault();
    }   
    mergeitem = () => {
        this.mergeAsync(this.state.editElement.id, this.modelProperty.current.value, this.modelValue.current.value, this.modelGroup.current.value);
    }
    removeItem = (id) => {
        const conf = window.confirm(`Do you want to remove item?`);
        if (conf) {
            this.removeAsync(id);
        }
    }
    showAddModal = (element) => {
        this.setState({ showModal: true, editElement: element });
    }
    hideModal = () => {
        this.setState({ showModal: false });
    }

    render() {
        return (
            <div className="MPassword">
                <h2 className="modulename"><img alt="password" src="./images/m.password.icon.png" height="32" /><span>Password Saver</span></h2>
                {!this.state.isLoaded &&
                    <form style={{ display: "flex", gap: "10px", maxWidth: "400px" }} onSubmit={this.submit}>
                        <input className="form-control" type="password" placeholder={this.state.isNew ? "Enter your new password" : "Password"} ref={this.value} />
                        <input className="btn btn-primary" type="submit" value="Load" />
                    </form>
                }
                {this.state.isLoaded &&
                    <div>
                        <Link to="#" onClick={() => this.showAddModal({ id: 0, name: '', value: '', group: '' })}>Add</Link>
                    <Table size="sm">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                            <th>Group</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody id="rows">
                        {this.state.data.map(c => {
                            return (
                                <tr key={c.name}>
                                    <td>{c.name}</td>
                                    <td>{c.value}</td>
                                    <td>{c.group}</td>
                                    <td><Link to="#" onClick={() => this.showAddModal(c)}>Edit</Link> <Link to="#" onClick={() => this.removeItem(c.id)}>Remove</Link></td>
                                </tr>
                                )
                        })
                        }
                    </tbody>
                    </Table>
                    <Modal toggle={this.hideModal} isOpen={this.state.showModal} aria-labelledby="contained-modal-title-vcenter" centered style={{height: "auto"}}>
                        <ModalHeader toggle={this.hideModal}>Modal title</ModalHeader>
                        <ModalBody>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Label>Property</Label>
                                <Input type="text" innerRef={this.modelProperty} defaultValue={this.state.editElement.name} />
                                <Label>Value</Label>
                                <Input type="text" innerRef={this.modelValue} defaultValue={this.state.editElement.value}/>
                                <Label>Group</Label>
                                <Input type="text" innerRef={this.modelGroup} defaultValue={this.state.editElement.group}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.mergeitem}>Add</Button>
                            <Button color="secondary" onClick={this.hideModal}>Close</Button>                            
                        </ModalFooter>
                    </Modal>
                    </div>
                }
                
            </div>
            )
    }
}