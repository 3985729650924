import React from 'react';

export class MExpenseGraph extends React.Component {
    
    render() {
        var els = [...this.props.data];

        let style = { display: 'flex', height: "100px", gap: "2px"};
        return (
            <div className="MExpenseGraph" style={style}>
                {els.map((a, index) => {
                    return <div key={index} style={{ flex: "1", marginTop: "auto", width: "4px", backgroundColor: "#666666", height: (a === 0 ? 1 : a) + "px" }}></div>
                })}
            </div>
        )
    }
}