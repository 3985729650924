import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Logo } from './Logo';
import { Login } from './Login';
import { Auth } from './Auth';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  
    render() {
      return (        
          
          <Container style={{height: "auto"}}>
              <Auth>
                  <header>
                      {window.location.pathname === '/verify' && <Logo />}
                  </header>
                  
                  <header>
                      <div className="d-none d-sm-block">
                          <div className="full">
                            <Logo />
                            <Login />
                          </div>
                      </div>
                      <div className="d-block d-sm-none">
                          <div className="short">
                              <a href="/" className="logoAsText" >
                                  <span className="groot">GROOT</span>
                                  <span className="plek">PLEK
                                      <small className="com">.com</small>
                                  </span>
                              </a>
                              <Login />
                          </div>
                      </div>
                  </header>
              </Auth>
            </Container>
        
    );
  }
}
