import React from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from 'reactstrap';

export class MExpenseAddGroupModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen };
        this.name = React.createRef();
    }
    componentWillReceiveProps(props) {
        this.setState({
            isOpen: props.isOpen,
            value: props.value
        });
    }
    onOk = () => {
        if (this.name.current.value.length > 0)
            this.props.onOk(this.name.current.value);
    }
    onCancel = () => {
        this.props.onCancel();
    }
    render() {
        return (
            <Modal toggle={() => this.onCancel()}
                isOpen={this.state.isOpen}
                aria-labelledby="contained-modal-title-vcenter" size="sm" >
                <ModalHeader>Add Group</ModalHeader>
                <ModalBody>                    
                    <FormGroup>
                        <Label>Name</Label>
                        <Input innerRef={this.name} defaultValue={this.state.value} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => this.onOk()}>OK</Button>
                    <Button color="secondary" onClick={() => this.onCancel()}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}