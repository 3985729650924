import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Verify } from './components/Verify';
import { MPassword } from './components/modules/MPassword';
import { Settings } from './components/Settings';
import { MExpense } from './components/modules/MExpense/MExpense';
import { MGames } from './components/modules/MGames/MGames';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/settings' component={Settings} />
            {/* <Route path='/my' component={MyPage} />*/}
            <Route path='/m.password' component={MPassword} />
            <Route path='/m.expense' component={MExpense} />
            <Route path='/m.games' component={MGames} />
            <Route path='/verify' component={Verify} />
      </Layout>
    );
  }
}
