export class ModuleApi {

    static handleError = (err) => {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Network Error'
        }));
    };
    static executeGet = async (url) => {
        var response = await (fetch(url).catch(ModuleApi.handleError));
        if (response.ok) {
            if (response.redirected)
                return window.location = '/';
            return response.json();
        } else {
            console.error('ModuleApi.executeGet.' + url + ' - bad');
            return Promise.reject(response);
        }
    }
    static executePost = async (url, jsonData) => {
        var response = await (fetch(url, {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(ModuleApi.handleError));
        if (response.ok) {
            if (response.redirected)
                return window.location = '/';
            return response.json();
        } else {
            console.error('ModuleApi.executePost.' + url + ' - bad');
            return Promise.reject(response);
        }
    }
    static execute = async (url, jsonData) => {
        if (jsonData === undefined)
            return await ModuleApi.executeGet(url);
        return await ModuleApi.executePost(url, jsonData);
    }
       
    static load = async () => {
        console.log('ModuleApi.load');
        var response = await (fetch('api/Account/modules').catch(ModuleApi.handleError));        
        if (response.ok) {
            console.info('ModuleApi.load - good');
            if (response.redirected)
                return window.location = '/';
            return response.json();
        } else {
            console.error('ModuleApi.load - bad');
            return Promise.reject(response);
        }
    }

    static password = class {
        static url = {
            get: 'api/MPassword/get',
            load: 'api/MPassword/load',
            merge: 'api/MPassword/merge',
            delete: 'api/MPassword/remove'
        }        

        static get = async () => {
            return await ModuleApi.execute(ModuleApi.password.url.get);
        }
        static load = async (password) => {
            return await ModuleApi.execute(ModuleApi.password.url.load, { password: password });        
        }
        static merge = async (id, name, value, group, guid) => {
            const data = { id, name, value, group, guid };
            return await ModuleApi.execute(ModuleApi.password.url.merge, data);                   
        }
        static delete = async (id, guid) => {
            const data = { id, guid };
            return await ModuleApi.execute(ModuleApi.password.url.delete, data);
        }
    }

    static expense = class {
        static url = {
            get: 'api/MExpense/get/:id',
            cost: 'api/MExpense/cost/:id',
            addpurchase: 'api/MExpense/addpurchase',
            mergecost: 'api/MExpense/mergecost',
            deletecost: 'api/MExpense/deletecost',
            deletecostitem: 'api/MExpense/deletecostitem',
            mergegroup: 'api/MExpense/mergegroup',
            deletegroup: 'api/MExpense/deletegroup',
            deleteboard: 'api/MExpense/deleteboard',
            mergeboard: 'api/MExpense/mergeboard',
            //merge: 'api/MPassword/merge',
            //delete: 'api/MPassword/remove'
        }

        static get = async (id) => {
            return await ModuleApi.execute(ModuleApi.expense.url.get.replace(':id', id ?? ""));
        }
        static cost = async (id) => {
            return await ModuleApi.execute(ModuleApi.expense.url.cost.replace(':id',id));
        }
        static addPurchase = async (id, stamp, value, note) => {
            var data = { id, stamp, value, note };
            return await ModuleApi.execute(ModuleApi.expense.url.addpurchase, data);
        }
        static mergeCost = async (id, groupid, name, plan, isclosed) => {
            var data = { id, groupid, name, plan, isclosed };
            console.log(data);
            return await ModuleApi.execute(ModuleApi.expense.url.mergecost, data);
        }
        static deleteCost = async (id) => {
            var data = { id };
            return await ModuleApi.execute(ModuleApi.expense.url.deletecost, data);
        }
        static deleteCostItem = async (id) => {
            var data = { id };
            return await ModuleApi.execute(ModuleApi.expense.url.deletecostitem, data);
        }
        static mergeGroup = async (boardid, groupid, name) => {
            var data = { boardid,groupid,name };
            return await ModuleApi.execute(ModuleApi.expense.url.mergegroup, data);
        }
        static deleteGroup = async (id) => {
            var data = { id };
            return await ModuleApi.execute(ModuleApi.expense.url.deletegroup, data);
        }
        static deleteBoard = async (id) => {
            var data = { id };
            return await ModuleApi.execute(ModuleApi.expense.url.deleteboard, data);
        }
        static mergeBoard = async (id, money, prevmoney) => {
            var data = { id, money, prevmoney };            
            console.log(data);
            return await ModuleApi.execute(ModuleApi.expense.url.mergeboard, data);
        }
    }

    static games = class {
        static url = {
            get: 'api/MGames/get'
        }
        static get = async () => {
            return await ModuleApi.execute(ModuleApi.games.url.get);
        }
    }
}