import React from 'react';
import md5 from 'md5';
import { Input, Button, Label, FormGroup, Row, Col } from 'reactstrap';

export class Verify extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = { ok: 0, password: '' };
        this.login = React.createRef();
        this.password = React.createRef();
    }
    componentDidMount() {
        var code = this.props.location.search.replace('?code=', '');
        this.check(code);
    }
    async check(code) {
        const response = await fetch('api/Account/verify', {
            method: 'POST',
            body: JSON.stringify({data: code}),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        var json = await response.json();
        this.setState({ ok: json.ok ? 1 : -1, code: code, password: json.password, message: json.message });
    }
    async apiloginas(name, pass, code) {
        if (name === undefined || name === '') {
            return;
        }
        if (pass === undefined || pass === '') {
            return;
        }
        pass = md5(pass);
        const response = await fetch('api/Account/loginas', {
            method: 'POST',
            body: JSON.stringify({ loginname: name, password: pass, code: code }), 
            headers: {
                'Content-Type': 'application/json'
            }
        });
        var json = await response.json();
        if (json.ok)
            window.location = '/';
        else
            this.setState({ ok: -1, message: json.message });
    }
    loginas = () => {
        this.apiloginas(this.login.current.value, this.password.current.value, this.state.code)
    }
    render() {
        return (
            <div className="Verify">
                {this.state.ok === 0 && <div>Please wait...</div>}
                {this.state.ok === -1 && <div className="text-danger">{this.state.message}</div>}
                {this.state.ok === 1 &&
                    <div style={{ height: "auto" }}>
                    <div className="text-success">
                        {this.state.message}
                    </div>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Col>
                                    <FormGroup>
                                        <Label for="inp">Enter your nickname (email name will be used instead if empty):</Label>
                                        <Input id="inp" innerRef={this.login} />
                                        <Label for="pass">Enter your password (required):</Label>
                                        <Input id="pass" innerRef={this.password} defaultValue={this.state.password}/>
                                    </FormGroup>
                                </Col>
                                <Button color="success" onClick={this.loginas}>Continue</Button>
                            </FormGroup>
                        </Col>
                        <Col></Col>
                    </Row>
                    
                 </div>}
            </div>
            
            )
    }
}