import React from 'react';

export function AuthGet() {   
    var xhr = new XMLHttpRequest();
    xhr.open('GET', 'api/Account/auth', false);
    xhr.send();
    if (xhr.status === 200) {
        return JSON.parse(xhr.responseText);
    }
    else {
        return { ok: false };
    }
}

export function Auth(props) {
    const t = AuthGet();  
    let control = props.children;   
    if (t.ok) {        
        if (props.only === undefined) {
            control = props.children[1];
        }
    }
    else {
        control = props.children[0];
    }
    return (
        <React.Fragment>
            {control}
        </React.Fragment>
    )
}